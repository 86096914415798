<template>
  <div class="trading-records-box">
    <top-home title="交易记录"></top-home>
    <div class="tapOne top-padding">
      <div class="query-box">
        <div class="tapOne-div">
          <div
            class="time"
            @click.capture="showPopupFun('start')"
          >
            <div>{{ timeStrat }}</div>
            <img src="@/assets/CostManager/sjx.png" />
          </div>
        </div>
        <div class="tapOne-div">
          <div
            class="time"
            @click.capture="showPopupFun('end')"
          >
            <div>{{ timeEnd}}</div>
            <img src="@/assets/CostManager/sjx.png" />
          </div>
        </div>
        <div>
          <van-button
            type="info"
            size="20px"
            @click="queryBillFun"
          >查询</van-button>
        </div>
        <!-- <div class="totalFee">总金额：¥ {{totalFee}}</div> -->
      </div>
      <van-list
        v-model="listLoading"
        :finished="listFinished"
        finished-text="没有更多了"
        @load="listOnLoadFun"
      >
        <div
          class="list-item"
          v-for="(item,index) in list"
          :key="index"
          @click="jumpDetails(item)"
        >
          <div class="item-content">
            <div class="content-lie">
              <div class="lie yf">{{item.title}}</div>
              <div class="je">¥ {{item.je}}</div>
            </div>
            <div class="content-lie">
              <div class="lie sj">
                <span>订单号:</span>{{item.ddh}}
              </div>
              <div class="zt">
                <span :class="[{'null-pay':item.jyzt=='未支付'},{'already-pay':item.jyzt=='已支付'},{'refund-pay':item.jyzt=='已退款'}]">{{item.jyzt}}</span>
              </div>
            </div>
            <div class="content-lie">
              <div class="lie sj">
                <span>支付时间:</span>
                {{item.zfsj}}
              </div>
              <div class="zt">
                <span>{{item.zffs}}</span>
              </div>
            </div>
            <div class="content-lie">
              <div
                class="lie"
                v-if="item.subbill==1"
              >
                <span>编号 | 名称:</span>
                {{item.htbh}} | {{item.xmmc}}
              </div>
            </div>
            <div class="content-lie">
              <div
                class="lie sj"
                v-if="item.subbill==1"
              >
                <span>{{item.fymc}}</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup
      v-model="isPopup"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-if="currType=='start'"
        v-model="times"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="(value)=>setTime(value,'start')"
        @cancel="setTime()"
      />
      <van-datetime-picker
        v-if="currType=='end'"
        v-model="timee"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="(value)=>setTime(value,'end')"
        @cancel="setTime()"
      />
    </van-popup>
    <van-popup
      v-model="isShowDetails"
      position="right"
      :style="{ width:'80%',height: '100%' }"
    >
      <Details :payData="currPayData"></Details>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPayLogApi } from '../../reuqest/api'
import Details from '@/components/TradingRecords/Details.vue'
export default {
  components: {
    Details,
  },
  data () {
    return {
      showDe: false,
      maxDate: new Date(),
      minDate: new Date(2018, 0, 1),
      list: [],
      listLoading: false,
      listFinished: false,
      getDataThrottle: false,
      tablePage: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      isPopup: false,
      currType: '',
      times: new Date(), //开始时间
      timee: new Date(), //结束时间
      totalFee: '',
      isShowDetails: false,
      currPayData: {},
    }
  },
  computed: {
    ...mapGetters(['wxLoginInfo']),
    timeStrat () {
      if (this.times) {
        let date = new Date(this.times)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        month = month <= 9 ? '0' + month : month
        return `${year}-${month}`
      } else {
        return ''
      }
    },
    timeEnd () {
      if (this.timee) {
        let date = new Date(this.timee)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        month = month <= 9 ? '0' + month : month
        return `${year}-${month}`
      } else {
        return ''
      }
    },
  },
  created () { },
  methods: {
    formatter (type, val) {
      if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    async listOnLoadFun () {
      this.listLoading = false
      // this.getDataThrottle = false
      if (this.getDataThrottle == false) {
        this.getDataThrottle = true
        this.getDataTimer = setTimeout(() => {
          return new Promise((resolve) => {
            let apiData = {
              openId: this.wxLoginInfo.openId,
              pageNo: this.tablePage.pageNo,
              pageSize: this.tablePage.pageSize,
            }
            if (this.timeStrat) {
              apiData.times = this.timeStrat
            }
            if (this.timeEnd) {
              apiData.timee = this.timeEnd
            }
            getPayLogApi(apiData).then((res) => {
              let data = res.data
              if (data.records) {
                this.list = [...this.list, ...data.records]
                this.tablePage.total = data.total
                this.tablePage.pageNo = this.tablePage.pageNo + 1
              } else {
                this.list = []
                this.tablePage.total = 0
              }
              if (this.list.length >= this.tablePage.total) {
                this.listFinished = true
              } else {
                this.listFinished = false
              }
              this.listLoading = false
              this.getDataThrottle = false
              resolve()
            })
          })
        }, 1000)
      }
    },
    jumpDetails (item) {
      this.isShowDetails = true
      this.currPayData = item
    },
    //显示弹窗
    showPopupFun (type) {
      this.currType = type
      this.isPopup = true
    },
    //查询
    queryBillFun () {
      this.list = []
      this.tablePage.pageNo = 1
      this.tablePage.total = 0
      this.listLoading = true
      this.listFinished = false
      this.listOnLoadFun()
    },
    setTime () {
      this.isPopup = false
    },
  },
}
</script>
 
<style lang="less" scoped>
.tapOne {
  background-color: rgb(245, 245, 245);
  height: 100vh;
  overflow-x: hidden;
  font-size: 16px;
}
.querySel {
  height: 40px;
  font-size: 16px;
  padding: 0 7px;
  width: 100%;
}
.list-item {
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 0;
  .item-img {
    margin: 0 10px;
    img {
      width: 45px;
    }
  }
  .item-content {
    width: 100%;
    padding: 8px;
    .je {
      color: #ffb54c;
      line-height: 22px;
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .content-lie {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 3px 0;
      color: #232323;
      text-align: left;
      .lie {
        flex: 1;
      }
      .zt {
        width: 100px;
      }
      span {
        color: #999;
      }
    }
  }
}
.query-box {
  display: flex;
  align-items: center;
  margin: 10px;
  .totalFee {
    flex: 1;
    text-align: right;
    color: #21a4fe;
    font-size: 14px;
  }
  .tapOne-div {
    .time {
      border-radius: 3px;
      border: 1px solid rgb(245, 245, 245);
      background-color: #fff;
      margin-right: 5px;
      height: 25px;
      padding: 3px 6px;
      display: flex;
      align-items: center;
      div {
        padding-right: 4px;
      }
      img {
        width: 10px;
      }
    }
  }
  .van-button {
    height: 100%;
    padding: 5px 10px;
  }
}
.null-pay {
  color: #ff734c !important;
}
.already-pay {
  color: rgb(0, 128, 0) !important;
}
.refund-pay {
  color: rgb(97, 197, 255) !important;
}
</style>