<template>
  <div>
    <div class="recordsDetails">
      <div style="background-color:#FFF;">
        <div class="MoneyUnit">
          <div class="tip">收费单位</div>
          <div class="dw">{{payData.skdw}}</div>
          <div class="money">¥{{payData.je}}</div>
        </div>
        <div class="details-box">
          <van-cell-group v-for="(item,index) in listData" :key="index">
            <van-cell :title="item.title" :value="item.value" />
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['payData'],
  data() {
    return {
      payKey: {
        fymc: '费用名称',
        jyzt: '交易状态',
        ssqy: '所属企业',
        sp: '商品',
        zffs: '支付方式',
        zfsj: '支付时间',
        ddh: '订单号',
        jybz: '交易备注',
      },
    }
  },
  computed: {
    listData() {
      let listData = []
      for (let key in this.payKey) {
        if (this.payData[key]) {
          listData.push({
            title: this.payKey[key],
            value: this.payData[key],
          })
        }
      }
      return listData
    },
  },
}
</script>
 
<style lang="less" scoped>
.recordsDetails {
  background-color: rgb(245, 245, 245);
  min-height: 100vh;
  .details-box {
    .van-cell {
      display: flex;
    }
    .van-cell__title {
      flex: none;
      padding-right: 10px;
      color: #232323;
    }
    .van-cell__value {
      flex: 1;
    }
  }
}
.MoneyUnit {
  padding: 20px 0;
  .tip {
    color: #999;
    font-size: 16px;
    font-weight: 400;
  }
  .dw {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 20px;
    padding-top: 4px;
    color: #232323;
  }
  .money {
    color: rgb(255, 76, 76);
    font-size: 20px;
  }
}
</style>